<template>
  <v-container id="driverEarning" fluid tag="section">
    <v-data-table
      :headers="columns"
      :items="driverList"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.driver_name`]="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Driver Earning Payout',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      moduleName: "DriverPayout",
      modulePermission: [],
      search: "",
      page: 1,
      driverList: [],
      loading: true,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      numbers: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Id", value: "_id", sortable: false },
        { sortable: false, text: "Driver Name", value: "driver_name" },
        { sortable: false, text: "Email", value: "email" },
        { sortable: false, text: "Phone Number", value: "phone_number" },
      ];
    },
  },
  methods: {
    getDriversEarningPayout() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "driverList/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.driverList = response.data.driverList.data;
            this.loading = false;
            this.totalPages = response.data.driverList.last_page;
            this.startRecord = response.data.driverList.from;
            this.endRecord = response.data.driverList.to;
            this.totalRecord = response.data.driverList.total;
            this.numbers = [];
            for (
              let num = response.data.driverList.from;
              num <= response.data.driverList.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getDriversEarningPayout();
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getDriversEarningPayout();
    },
  },
  mounted() {
    this.getDriversEarningPayout();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
  },
};
</script>

<style>
</style>
